/* Basic reset */
body, html {
  font-family: 'Outfit', sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.logo-item img {
  width: auto; 
  height: auto; 
}

.navbar {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
    border-bottom-width: 0.5px;
    border-bottom-color: #F2F4F7;
  position: fixed;
  width: 100%;
  top: 0; 
  z-index: 1000; 
}

.right-line {
    border-right-width: 2px;
    border-right-color: #D0D5DD;
    height: 30px;
    margin-left: -35px;
    margin-right: 30px
}

.connect-line {
    border-right-width: 2px;
    border-right-color: #E5E5E5;
    height: 100px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 50px
}

.nav-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin-right: 30px;
  color: #667085
}

.nav-links a {
  text-decoration: none;
  color: #003366;
  font-weight: 600
}

.nav-links .active2 {
  color: #0056b3;
  font-weight: 600;
   position: relative;
}

.nav-links .active2::after {
  content: '';
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #0056b3;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.nav-links .active2:hover::after, 
.nav-links .active2.active2::after { 
  transform: scaleX(1); 
  }

.nav-links .grey {
  color: #667085;
   position: relative;
}

.grey h2 {
  color: #667085;
   position: relative;
}

.nav-links .grey:hover {
  color: #0056b3;
  font-weight: 600;
}

.nav-links .grey::after {
  content: '';
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  height: 2px;
  top: 20px;
  background-color: #0056b3;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.nav-links .grey:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left; 
}

.arrow {
  margin-left: 10px;
  width: 20px;
  height: 20px
}

.popup-container .grey {
  position: relative;
}

.popup-container:hover .grey {
  color: #0056b3; 
  font-weight: 600;
}

.popup-container .grey::after {
  content: '';
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #0056b3;
  transform: scaleX(0);
  transform-origin: bottom left; 
  transition: transform 0.3s ease-in-out;
}

.popup-container:hover .grey::after {
  transform: scaleX(1); 
}

.popup {
  position: absolute;
  align-self: center;
  display: flex;
  gap: 15px;
  background-color: white;
  padding: 20px;
  z-index: 1000;
  margin-top: 15px;
  width: 1210px;
  margin-left: -250px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 8px;
}

.popup2 {
  position: absolute;
  align-self: center;
  display: flex;
  gap: 15px;
  background-color: white;
  padding: 20px;
  z-index: 1000;
  margin-top: 15px;
  width: 1210px;
  margin-left: -370px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 8px;
}

.popup-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.popup-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.popup-item:hover {
  transform: translateX(5px);
}

.popup-item img {
  width: 40px;
  height: 40px;
  margin-top: -15px;
  transition: transform 0.2s ease;
}

.popup-item:hover img {
  transform: scale(1.05);
}

.popup-item h4 {
  margin: 0 0 2px 0;
  font-size: 16px;
  font-weight: 600;
  color: #101828;
  transition: color 0.2s ease;
}

.popup-item:hover h4 {
  color: #0056b3;
}

.popup-item p {
  margin: 0;
  font-size: 14px;
  color: #667085;
  line-height: 1.4;
}

.pop-app-store, .pop-app-store2 {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #E5E7EB;
}

.pop-app-store h2, .pop-app-store2 h2 {
  font-size: 18px;
  font-weight: 600;
  color: #101828;
  width: 320px;
  text-align: right;
  margin: 0 0 4px 0;
}

.pop-app-store p, .pop-app-store2 p {
  font-size: 14px;
  font-weight: 500;
  color: #667085;
  width: 320px;
  text-align: right;
  margin: 0 0 12px 0;
}

.pop-app-store {
margin-left: 470px
}

.pop-app-store2 {
margin-left: 470px;
margin-top: 120px
}

.popup-app-store-icons {
  width: 250px;
  height: 50px;
  margin-top: 20px;
  margin-left: 70px
}

.popup-app-store-icons2 {
  width: 250px;
  height: 50px;
  margin-top: 20px;
  margin-left: 70px
}

.language-selector {
  display: flex;
  align-items: center;
  margin-left: 250px
}

.global-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.language-text {
  color: #003366;
  font-weight: 600
}

.auth-buttons {
  display: flex;
  align-items: center;
  margin-left: 350px
}

.login-button, .download-button {
  margin-left: 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.login-button {
  color: #003366;
  font-weight: 600
}

.download-button {
  background-color: #003366; 
  color: white;     
  padding: 5px 20px;   
  cursor: pointer; 
}

.download-button:hover {
  background-color: #D1E7FD;
  color: #003366
}

.down-arrow {
  width: 15px; 
  height: auto;
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.popup-container:hover .down-arrow {
  transform: rotate(180deg);
}

.logoname {
  color: #003366;
  margin-right: 30px;
}

.main-content {
  flex: 1;
  background-image: url('./assets/Group 52.png');
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: contain; 
  padding: 20px; 
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: white;
  width: 100%; 
  height: 100vh; 
  box-sizing: border-box; 
}

.garage-content {
  flex: 1;
  background-image: url('./assets/Group 64.png');
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: contain; 
  padding: 20px; 
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: white;
  width: 100%; 
  height: 100vh; 
  box-sizing: border-box; 
}

.vehicle-content {
  display: flex;
  align-items: center; 
  justify-content: center; 
}

.hero-vehicle { 
  color: white;
  margin-right: 50px;
}

.divider-container {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.heading-divider {
  border-top: 2px solid black;
  width: 1200px
}

.hero-content { 
  color: white;
  margin-right: 750px;
}

.section-grey-background {
  background: linear-gradient(180deg, white 0%, #F0F0F5 100%);
  padding: 50px 0;
  text-align: center;
  margin-top: 50px
}

.section-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px; 
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px
}

.text-and-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  text-align: left;
  max-width: 500px;
  margin-right: 20px
}

.text-and-icons h2 {
  font-size: 3.5em;
  color: #101828;
  font-weight: 600;
  margin-top: 30px;
}

.text-and-icons p {
  font-size: 22px;
  color: #667085;
  margin-top: 10px;
}

.text-and-icons2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  text-align: left;
  max-width: 600px;
  margin-right: 30px
}

.text-and-icons2 h2 {
  font-size: 3.5em;
  color: #101828;
  font-weight: 600;
  margin-top: 30px;
}

.text-and-icons2 p {
  font-size: 22px;
  color: #667085;
  margin-top: 10px;
    max-width: 500px;
}

.app-store-icons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.store-icon {
  width: auto;
  height: auto;
}

.store-icon-up {
  width: auto;
  height: auto;
    margin-top: 15px;
}

.image-column img {
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}


.review-section {
  padding: 100px;
  background-color: #FAFAFA;
     justify-content: center; 
     align-content: center
}

.review-sec-text h2 {
  font-size: 2em;
   margin-top: 10px;
  color: #101828;
  font-weight: 600;
  text-align: flex-start;
  margin-left: 20px
}

.review-sec-text p {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #667085;
  text-align: flex-start;
  margin-left: 20px
}

.cards-section {
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding: 100px 50px;
  background-color: #FAFAFA;
}

.button-align {
  background-color: #f8f8f8;
         justify-content: center; 
     align-items: center;
}

.cards-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  justify-content: center; 
  max-width: 1400px
}

.cards-white {
  width: 40%; 
  margin:20px;
  background-color: #FAFAFA;
  border-width: 0.5px;
  padding: 15px;
  border-radius: 8px;
  overflow: hidden
}

.card {
  width: 100%; 
    border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.card-image img {
  width: 100%;
  height: auto;
    padding: 50px;
    background-color: white;
    border-radius: 8px;
  transition: opacity 0.3s ease; 
}

.card-text {
  background-color: #FAFAFA;
  color:  #101828;
  font-size: 24px;
  display: flex;
  font-weight: bold;
  text-align: flex-start;
  padding: 30px 20px;
  transition: opacity 0.3s ease; 
}

.icon {
  width: 25px;
  height: 25px;
  margin-top: 5px;
  background-color: #E6EBF0CC;
  padding: 5px;
  border-radius: 13px;
  position: absolute;
  right: 40px

}

.card-hover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  opacity: 0; 
  visibility: hidden; 
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  transform: translateY(0);
}

.hoverp {
  font-size: 2em;
  margin-top: 5px;
  margin-bottom: 10px;
  color: white;
  font-weight: 600;
}

.hoverp2 {
  font-size: 24px;
  color: white;
  text-align: flex-start;
  font-weight: light
}

.card:hover .card-hover  {
  opacity: 1; 
  visibility: visible; 
  transition: opacity 0.3s ease, visibility 0s 0s;
}

.card:hover .card-image img,
.card:hover .card-text {
  opacity: 0; 
}

.card-icon {
  width: 50px;
  height: 50px;
   margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
}

.learn-more-btn {
  padding: 10px 20px;
  font-size: 20px;
  background-color: white;
  color: black;
  font-weight: 500;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 40px
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(250px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.learn-more-btn:hover {
  background-color: #0056b3;
}

.card-text {
  position: relative;
  z-index: 1; 
}

.card-content-text {
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
}

.card-content-text2 {
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
  margin-top: 40px
}

.card-content-text h2 {
  font-size: 2em;
   margin-top: 10px;
  color: #101828;
  font-weight: 600;
  text-align: center;
}

.card-content-text2 h2 {
  font-size: 2em;
   margin-top: 10px;
   width: 700px;
  color: #101828;
  font-weight: 600;
  text-align: center;
}

.card-content-text p {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #667085;
  text-align: center;
}

.mid-img {
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
padding: 50px 100px;
margin-bottom: 50px
}

.mid-img img{
width: auto;
height: auto
}

.sub-heading {
  max-width: 380px;
  font-size: 1em; 
  margin: 10px 0;
  color: black;
}

.cta-buttons {
  max-width: 600px;
  margin: 40px 0;
  display: flex;
}

.cta-button {
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 20px;
}

.downloadnow-button {
  background-color: #003366; 
  color: white;
  padding: 10px 15px; 
    border-radius: 10px
}

.downloadnow2-button {
  background-color: #EAECF0; 
  color: white;
  padding: 10px 15px; 
  margin-right: 20px;
  color: #003366;
  font-weight: 500;
  border-radius: 10px
}

.downloadnow-button:hover {
  background-color: #0056b3;
}

.downloadnow2-button:hover {
  background-color: #0056b3; 
  color: white;
   font-weight: 400;
}

.downloadgarage-button {
  background-color: #003366; 
  color: white;
  padding: 5px 20px; 
  max-width: 600px
}

.learn-more-button {
  border-color: #003366;
  color: #003366;
  border-width: 1px;
  padding: 5px 20px; 
}

.cta-button:hover {
  background-color: #D1E7FD;
  color: #003366
}

.white-background-section {
  display: flex;
  flex-direction: row; 
  align-items: center; 
  justify-content: center; 
  border-radius: 10px; 
  gap: 50px;
  margin-top: 30px;
  padding: 50px
}

.second-white-background-section {
  display: flex;
  flex-direction: row; 
  align-items: center; 
  justify-content: center; 
  border-radius: 10px; 
  gap: 100px;
  margin-top: 30px;
  padding: 50px
}

.realtime-image {
  max-width: 90%;
  height: auto;
  border-radius: 10px;
}

.quickEffortlessImage {
  max-width: 90%;
  height: auto;
  border-radius: 10px;
}

.pattern-container {
  align-self: center;
}

.text-content {
  align-self: center;
}

.text-overlay {
  align-self: center;
  width: 530px
}

.text-overlay2 {
  align-self: center;
  width: 530px
}

.text-overlay h2 {
  font-size: 2em;
  margin: 0;
  font-weight: 500;
  color: #003366;
}

.text-overlay2 h2 {
  font-size: 2em;
  margin: 0;
  font-weight: 500;
  color: #003366;
}

.text-overlay p {
  font-size: 1em; 
  color: grey; 
  margin-top: 10px; 
}

.text-overlay2 p {
  font-size: 1em; 
  color: grey; 
  font-size: 18px ;
margin-top: -10px ;
margin-bottom: 20px ;
width: 380px
}

.text-content h3 {
  font-size: 1.9em; 
  margin-bottom: 10px;
  color: #003366; 
  width: 530px;
  font-weight: 500;
}

.text-content p {
  font-size: 1em; 
  color: grey; 
  margin-bottom: 20px; 
  width: 380px;
}

.image-content img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; 
}

.download-black {
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 50px;
  width: 210px;
  height: 50px;
  border-width: 1px;
  border-color: black;
}

.center-download {
align-self: center;
margin-bottom: 100px;
align-items: center;
justify-content: center;
display: flex;
}

.download-black .icon {
  width: 24px;
  height: 24px; 
  margin-right: 5px; 
}

.statistics {
  max-width: 380px;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.stat {
  text-align: center;
}

.stat h3 {
  margin: 0;
  font-size: 1.4em; 
  color: black;
  font-weight: bold;
}

.stat p {
  margin: 0;
  font-size: 0.8em; 
  color: black;
  font-weight: bold;
}

.overlay-buttons {
  display: flex;
  margin-top: 50px;
}

.download-blackpattern {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
  margin-right: 20px;
  width: 210px;
  height: 50px;
  border-width: 1px;
  border-color: black;
}

.services-button {
  background-color: #E6EBF0;
  color: #003366;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 120px;
  margin-bottom: 20px;
}

.learn-more-pattern {
  display: flex;
  align-items: center;
  border-color: black;
  color: black;
  border-width: 1px;
  border-radius: 30px;
  padding: 5px 20px;
  cursor: pointer;
  width: 200px;
  height: 50px;
  text-align: center;
}

.right-arrow {
  width: 22px;
  height: auto;
  margin-left: 15px;
}

.download-blackpattern .icon {
  width: 24px; 
  height: 24px; 
  margin-right: 5px; 
}

.download-blackpattern:hover .icon {
  filter: invert(1); 
}

.download-black:hover .icon {
  filter: invert(1); 
}

.learn-more-pattern:hover {
  background-color: #003366; 
  color: white;
}

.download-blackpattern:hover {
  background-color: white; 
  color: black;
}

.download-black:hover {
  background-color: white; 
  color: black;
}

.download-black-center:hover {
  background-color: white; 
  color: black;
}

.download-blackpattern:hover .icon {
color: black;
}

.learn-more-pattern:hover .right-arrow {
  filter: brightness(0) invert(1); 
}

.text-overlay2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 350px
}

.contentg-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 450px
}

.number-line {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px
}

.number {
  font-size: 40px;
  font-weight: bold;
  color: #003366;
}

.line {
  width: 2px;
  height: 80px; 
  background-color: #003366; 
  margin-right: 20px ;
}

.text-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.text-column h2 {
  margin: 0;
  font-size: 20px; 
  color: black
}

.text-column p {
  margin: 0;
  font-size: 15px; 
}

.services-section {
  display: flex;
  flex-direction: row; 
justify-content: center;
  gap: 30px; 
 align-self: center;
align-content: center;
margin-top: 30px;
margin-left: 50px;
}

.services-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    align-self: center;
}

.services-text {
  font-size: 2.2em;
  color: #003366;
  font-weight: 600;
  margin-bottom: 20px;
  margin-left: 20px
}

.services-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.boxes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;  
  padding: 10px;
  align-self: center;
}

.box {
  background-color: #D1E7FD;
  border-radius: 8px;
  padding: 20px;
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  width: calc(45% - 20px);
  box-sizing: border-box;
  height: 180px; 
  text-align: center; 
transition: background-color 0.3s ease, color 0.3s ease;
position: relative;
}

.box:hover {
  background-color:  #003366
}

.box:hover .default-heading {
  color: white; 
}

.box:hover .hover-content {
  opacity: 1; 
  visibility: visible;
}

.hover-content {
  position: absolute;
padding: 20px;
  text-align: flex-start;
  opacity: 0;
  visibility: hidden; 
  pointer-events: none;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.hover-heading {
  color: white;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.hover-subheading {
  color: white;
  margin: 5px 0 0;
  font-size: 14px;
}

.box:hover {
  background-color: black;
  text-align: left;
}

.box:hover .default-heading {
  opacity: 0; 
}

.box:hover .hover-content {
  opacity: 1; 
  visibility: visible;
}

.footer-section {
  background-image: url('./assets/Frame 1000004066.png'); 
  background-size: cover;
  background-position: center;
  padding: 80px;
  text-align: flex-start;
  color: white; 
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: row;
}

.footer-content {
  width: 600px; 
  justify-content: center;
align-content: center;
margin-right: 600px;
}

.tiny-text {
  font-size: 14px; 
  margin-bottom: 20px;
}

.footer-heading {
  font-size: 32px; 
}

.footer-heading2 {
  font-size: 32px; 
  margin-bottom: 20px;
}

.footer-button {
  display: flex;
}

.footer-icon {
  width: 30px; 
  height: auto;
  margin-right: 10px; 
}

.button-text {
  font-size: 14px; 
}

.big-button-text {
  font-size: 30px; 
}

.image-container {
  position: relative;
  width: auto;
  width: auto;
  padding: 10px 
}

.background-image {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 350px;
  color: white;
}

.overlay h2 {
  font-size: 18px;
  margin: 0;
  color: white;
  text-align: left;
}


.overlay p {
  font-size: 1em;
  margin: 10px 0;
  color: white;
  text-align: left;
}

.bottom-image {
  margin-top: 20px; 
  width: 400px; 
  height: 100px;
  border-radius: 8px; 
}

.closing-footer {
  background-color: black;
  color: white;
  padding: 20px;
  position: relative;
  text-align: center;
}

.closing-footer-container {
  width: 100%;  
  justify-content: center;
  align-items: center;
}

.closing-footer-background-text {
  font-size: 300px;
  margin-top: 70px;
  color: grey;
  opacity: 0.05;
  background-color: transparent;
  line-height: 170px; 
  white-space: nowrap; 
  text-align: center;
}


.closing-footer-footer-content {
  display: flex;
  justify-content: center; 
  align-items: center;  
  margin-top: 30px
}

.closing-footer-footer-header {
  display: flex;
    justify-content: center; 
  align-items: center; 
  margin-bottom: 20px;
  margin-right: 300px;
      margin-left: 60px
}

.closing-footer-store-icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;  
  width: auto; 
}

.icon-store {
  width: 40px; 
  height: 40px;
  object-fit: contain;
  margin-top: 30px
}

.closing-footer-get-in-touch h2 {
  margin: 0;
  font-size: 55px;
}

.closing-footer-arrow-down {
  height: 15px;
  width: 15px;
}

.closing-footer-footer-sections {
  display: flex;
  margin-bottom: 20px;
}

.closing-footer-h3 {
  display: flex;
}

.closing-footer-section {
margin-right: 100px;
}

.closing-footer-absolute {
display: flex;
right: 3%
}

.closing-footer-section h3 {
  font-size: 16px;
  color: grey;
  margin-bottom: 20px;
  text-align: start;
}

.closing-footer-section h2 {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: start;
  font-weight: 500;
}

.closing-footer-section p {
  margin: 0;
  font-size: 16px;
  margin-bottom: 12px;
  text-align: start;
  font-weight: lighter;
}

.closing-footer-section p:hover {
  color: grey;
}

.closing-footer-margin {
  margin-top: 60px;
}

.closing-footer-extra p {
  margin: 0;
  font-size: 18px;
  text-align: start;
  font-weight: lighter;
  margin-left: 105px
}

.closing-footer-social-media {
  display: flex;
  justify-content: center; 
  gap: 5px; 
  margin-bottom: 30px;
  margin-top: 20px
}

.closing-footer-social-media .icon,
.closing-footer-social-media i {
  margin: 5px; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; 
  height: 40px; 
  text-align: center; 
  transition: background-color 0.3s ease, transform 0.3s ease; 
}

.closing-footer-social-media .icon {
  object-fit: cover; 
  width: 30px; 
  height: 30px; 
  border-radius: 50%; 
  padding: 5px; 
  margin-bottom: 30px;
}

.closing-footer-social-media i:hover,
.closing-footer-social-media .icon:hover {
  background-color: black; 
  color: #000; 
  transform: scale(1.1); 
}

.closing-footer-social-media .icon {
  object-fit: cover; 
}

.copyright h3 {
  color: grey;
  margin-top: 20px ;
  font-size: 16px
}

.copyright {
margin-right: 1150px;
  margin-top: 20px ;
}

.closing-footer-divider {
  border: none;
  border-top: 2px solid grey;
  margin-top: 40px;
  opacity: 0.5;
}

.footerlogo-image {
  width: 80px;
  height: 50px;
  background-color: white;
  padding: 10px;
  margin-top: 60px
}

.arrowdown {
  width: 120px; 
  height: 120px;
  margin-left: -10px;
  margin-top: -10px;
}

.car-owner {
  color: white;
  margin-top: 60px;
  background-image: url('./assets/Pattern.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.garage-background {
  background-image: url('./assets/Pattern.png'); 
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
  background-attachment: fixed; 
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center; 
}

.heading {
  font-size: 2.5rem;
  color: #003366;
  margin-bottom: 15px;
  font-weight: 600;
  width: 800px;
  margin: 0 auto; 
  margin-top: 50px;
}

.subheading {
  font-size: 1.3em;
  color: black;
  margin-bottom: 30px;
  width: 700px;
  margin: 0 auto; 
  margin-top: 10px;
}

.owner-download-button {
  background-color: #003366;
  color: #fff;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 30px;
}

.owner-download-button:hover {
  background-color: #D1E7FD;
  color: #003366
}

.download-icon {
  margin-right: 8px;
}

.owner-boxes-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px; 
  text-align: flex-start;
}

.owner-box {
  background-color: #D1E7FD;
  padding: 20px;
  border-radius: 10px;
  width: 400px; 
  height: 300px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: start;
}

.owner-box:hover {
  background-color:  #003366
}

.owner-box:hover .owner-box-heading {
  color: white; 
}

.owner-box:hover .owner-box-small-text,
.owner-box:hover .owner-box-subheading {
  color: #D1E7FD; 
}

.owner-box:hover .owner-icon {
  background-color: lightgrey; 
  filter: invert(1) brightness(3);
}

.owner-box-content {
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  justify-content: flex-start
}

.owner-box-small-text {
  display: block;
  font-size: 16px;
  color: black;
  margin-top: 25px;
  text-align: start;
}

.owner-box-heading {
  font-size: 28px;
  margin: 10px 0;
  color: #003366;
  text-align: flex-start;
  font-weight: 600
}

.owner-box-subheading {
  font-size: 16px;
  margin-bottom: 25px;
  color: black;
  text-align: start;
}

.owner-box-button {
  display: flex;
  width: 150px;
  height: 45px
}

.owner-icon {
  margin-right: 8px;
  width: 25px;
  background-color: rgba(0, 51, 102, 0.1); 
  border-radius: 50%; 
  padding: 4px; 
  display: inline-block;
  box-sizing: content-box; 
}

.feedbacks-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px
}

.feedback-nav-button {
  background: none;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 40px
}

.feedback-content {
  display: flex;
  align-items: center;
}

.feedback-image {
 width: 250px;
 height: 250px
}

.feedback-text-container {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  margin-left: 50px;
}

.feedback-name {
  font-weight: 500;
}

.feedback-text {
  margin-top: 0.5rem; 
  font-size: 25px;
  font-style: italic;
  color: #003366;
  width: 700px;
  margin-top: 10px
}

.get-started-garage h2 {
  font-size: 2em;
  color: #003366;
  font-weight: 500;
  width: 1200px
} 

.get-started-garage {
display: flex;
  margin-bottom: 30px;
  margin-top: 50px;
  align-self: center;
  justify-content: center;
  text-align: flex-start;
} 

.all-sections {
  padding: 5%;
}

.white-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 50px
}

.white-box {
  display: flex;
  align-items: center;
  background-color: #fff; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  width: 50%; 
  max-width: 550px; 
  padding: 40px
}

.white-box:hover {
  background-color: black; 
}

.white-box:hover .white-box-heading{
  color: white; 
}

.white-box:hover .white-box-icon {
  filter: invert(1) brightness(2);
}

.white-box:hover .white-box-number-circle
{
  background-color: black
}

.white-box-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  flex: 1;
}

.white-box-number-circle {
  width: 40px;
  height: 40px;
  background-color: #003366;
  color: #fff; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin-bottom: 10px;
}

.white-box-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
  margin-top: 30px;
  color: #003366;
}

.white-box-text-content {
  margin-bottom: 10px;
}

.white-box-heading {
  font-size: 20px;
  margin: 0;
  color: black;
  font-weight: 600
}

.white-box-subheading {
  font-size: 18px;
  margin: 0;
  color: #666;
}

.white-box-right-image img {
  width: auto;
  height: auto;
  display: block;
}

.box-pattern-background {
  background-image: url('./assets/Pattern.png'); 
  align-items: center;
  justify-content: center;
}

.privacy-policy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  line-height: 1.6;
  margin-top: 50px
}

.privacy-policy-container h1 {
  color: #003366;
}

.privacy-policy-container h1 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  font-family: 'Judson', serif;
  font-weight: 600;
  text-align: center;
}

.privacy-policy-container h2 {
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 500;
}

.privacy-policy-container h3 {
  font-size: 1rem;
  margin-top: 15px;
}

.privacy-policy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.privacy-policy-container address {
  font-style: normal;
  line-height: 1.4;
}

.faq-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 900px
}

.faq-container h2 {
font-size: 2.5em;
font-weight: bold;
text-align: center;
color: #101828
}

.faq-contr p {
font-size: 20px;
text-align: center;
margin-bottom: 70px;
margin-top: 20px;
color: #667085
}

.faq-section {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  cursor: pointer; 
}

.faq-section:hover {
  border-width: 1px;
  border-color: #000;
  border-radius: 10px;
  padding: 20px 18px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
  transition: all 0.3s ease; 
  width: 890px;
  margin-left: -10px
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  color: #101828;
}

.faq-answer {
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.5;
  color: #667085;
}

.faq-answer.open {
  opacity: 1;
  transform: scaleY(1); /* Ensures it's fully visible */
  animation: bounce 0.4s ease-out; /* Add bounce animation */
}

.plus-arrow {
width: 25px;
height: 25px
}

.get-in-container {
  display: flex;
  background-color: rgba(204, 219, 234, 0.664); 
padding-top: 200px;
padding-bottom: 200px;
  align-items: center;
  justify-content: center;
  width: 2500px;
}

.contact-container {
  display: flex;
flex-direction: column;
margin-right: 50px;
align-self: center;
max-width: 700px;
}

.contact-back{
  background-image: url('./assets/Pattern.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.contact-us {
  padding: 20px;
  border-radius: 8px;
  margin-right: 10px;
  color: black;
  max-width: 450px
}

.contact-us h2 {
  font-weight: 600;
  font-size: 38px;
  }

  .contact-us h3 {
    font-size: 19px;
    margin-bottom: 20px;
    color: #666
    }

    .contact-us p {
      display: flex;
      align-items: center;
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 500;
    }
    
    .contact-us .icon {
      width: 20px; 
      height: 20px;
      margin-right: 20px;
    }

    .support p2 {
      font-size: 19px;
      font-weight: 500;
      margin-top: 50px;
      text-decoration: underline;
      padding: 20px;
    }

    .contact-info {
      padding: 20px;
    }
    
    .info-sections {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-top: 20px;
      width: 700px
    }
    
    .info-section {
      flex: 1;
    }
    
    .info-section h2 {
      margin-bottom: 10px;
      font-size: 1.5em; 
      font-weight: 600;
    }
    
    .info-section p {
      margin-bottom: 10px;
      color: #666;
      font-size: 19px;
    }

.get-in-touch-form {
  padding: 40px;
  background-color: #fff; 
  border-radius: 20px;
  max-width: 1000px;
  align-self: center;
}

.get-in-touch-form h2 {
font-weight: 600;
font-size: 38px;
}

.get-in-touch-form h3 {
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 20px;
  }

.form-row {
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #D0D5DD;
  border-radius: 0px,
}

.input-container {
  position: relative;
  margin-bottom: 15px; 
}

.input-icon {
  position: absolute;
  left: 20px; 
  top: 45%;
  transform: translateY(-50%);
  width: 20px; 
  height: 20px;
  pointer-events: none; 
}

input[type="email"],
input[type="tel"] {
  width: 100%;
  padding-right: 10px; 
  box-sizing: border-box;
  border-radius: 0px,
}

.country-code {
  position: absolute;
  left: 10px;
  top: 40%;
  transform: translateY(-50%);
  font-weight: 500;
  pointer-events: none;
}

.phone-input-wrapper {
  display: flex;
  max-width: 100%
}

.phone-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.phone-number-input {
  height: 45px;
  border: 1px solid #D9D9D9;
  padding: 15px; 
  font-size: 16px;
  width: 73%;
  margin-left: 27%;
  position: relative; 
  top: -60px;
  border-radius: 0px,
}

.input-move {
margin-bottom: -40px;
margin-top: 10px
}

.phone-number-input::placeholder {
  color: #999;
}

.react-tel-input .selected-flag {
  background-color: transparent !important;
  border: none !important;
}

.arrow-icon {
  position: absolute;
  left: 50px;
  top: 40%;
  transform: translateY(-50%);
  font-weight: 500;
  pointer-events: none;
}

.separator-line {
  position: absolute;
  left: 80px;
  top: 40%;
  transform: translateY(-50%);
  height: 43px;
  width: 2px;
  background-color: #ccc;
  margin-right: 10px;
}

input[type="tel"]::before {
  content: '|';
  position: absolute;
  left: 45px; 
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  color: #000;
  pointer-events: none;
}

input[type="tel"] {
  padding-left: 100px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #D0D5DD;
  height: 200px;
   border-radius: 0px,
}

.textarea-container {
  position: relative;
  margin-bottom: 15px;
}

.word-count {
  position: absolute;
  right: 15px;
  bottom: 30px;
  font-size: 0.9em;
  color: #666;
}

.submit-button {
  background-color: #003366;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 100%
}

.submit-button:hover {
  background-color: #D1E7FD;
  color: #003366;
}

.contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  margin-top: 20px;
  margin-bottom: 100px
}

.contact-img {
  max-width: 600px;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.contact-content {
align-items: center;
justify-content: center;
align-self: center
}

.contact-content h2 {
  font-size: 2.5em;
  width: 600px;
   margin-top: 100px;
  color: #101828;
  font-weight: 600;
  text-align: center;
}

.contact-content p {
  font-size: 20px;
  width: 600px;
  margin-top: 10px;
  margin-bottom: 50px;
  color: #667085;
  text-align: center;
}

.get-in-touch-form label {
  font-size: 14px; 
  font-weight: bold;  
  color: #101828;  
  margin-bottom: 4px;  
  display: block;   
}

.privacy-policy {
  font-size: 20px;
  color: #666;
  text-align: center;
}

.privacy-policy a {
  color: black;
  font-weight: 600;
}

.bridging-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px
}

.brtext-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.bradditional-text {
  flex: 1;
  font-size: 18px;
  width: 400px;
  margin-left: 200px;
  text-align: start;
  margin-top: 80px;
}

.full-page-section {
  width: 100%;
  height: 100vh; 
  background-image: url('./assets/Frame 1000003983.png'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
}

.about-container {
  display: flex;
  background-color: rgba(204, 219, 234, 0.664); 
padding-top: 200px;
  align-items: center;
  justify-content: center;
  width: 3000px;
}

.full-page-section img {
  visibility: hidden;
}

.bridging-text {
  font-size: 38px; 
  font-weight: 600;
  text-align: center; 
  font-family: 'Judson', serif;
  width: 350px;
  text-align: start;
}

.dark-blue {
  color: #003366;
}

.light-blue {
  color: #5DADE2;
}

.core-values-container {
align-self: center;
width: 1030px;
}

.core-values-container2 {
align-self: center;
}

.core-values-heading {
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: bold;
  width: 450px;
}

.core-values-subheading {
  font-size: 18px;
  margin-bottom: 20px;
  width: 450px;
}

.about-white-boxes-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  padding: 20px;
}

.about-box {
  background-color: white;
  padding: 20px;
  flex: 1 1 calc(50% - 20px); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  text-align: center;
  border-radius: 10px;
  width: 200px;
  height: 150px
}

.about-box-heading {
  font-size: 20px;
  margin: 10px;
  font-weight: 600;
}

.about-box-subheading {
  font-size: 14px;
  margin: 0;
}

.about-white-background-section {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  border-radius: 10px; 
  gap: 50px;
  margin-top: 30px;
  padding: 50px
}

.join-team-section {
margin-bottom: -100px; 
}

.join-team-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.join-team-heading {
  font-size: 38px;
  font-weight: bold;
  height: 300px;
  width: 300px;
}

.join-team-subheading {
  font-size: 18px;
}

.join-team-subheading-row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 450px;
  height: 300px;
  margin-left: 280px;
}

.join-team-subheading-bottom {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 10px;
}

.image-grid {
display: flex;
flex-direction: column;
  gap: 20px;
  justify-content: center; 
  align-items: center;
  margin-top: 150px;
  margin-bottom: 150px
}

.first-row {
display: flex;
flex-direction: row;
  gap: 20px;
flex-direction: row
}

.second-row {
display: flex;
flex-direction: row;
  gap: 20px;
flex-direction: row
}

.column-row {
display: flex;
flex-direction: column;
  gap: 40px;
}

.column-co {
display: flex;
flex-direction: column;
  gap: 20px;
}

.form {
  background-color: white;  
  border: 2px solid #003366; 
  padding: 20px;   
  border-radius: 10px;  
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;  
}

.form label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form input,
.form select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; 
  box-sizing: border-box;  
}

.form button {
  background-color: #003366;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.form button:hover {
  background-color: #5DADE2; 
}

.form-heading {
  font-size: 18px;    
  font-weight: bold;  
  color: #003366;   
  margin-bottom: 10px;  
}

.downloads {
  position: relative;
  margin-top: -5px;
    color: #003366;
  font-weight: 600
}

.downloads .dropdown-menu {
  display: none;     
  position: absolute;    
  top: 100%;       
  left: 0;
  background-color: white; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  padding: 10px 0;
  list-style: none;
  z-index: 1000;
}

.downloads:hover .dropdown-menu {
  display: block;        
}

.dropdown-item {
  padding: 10px 20px;
  white-space: nowrap;   
  text-decoration: none;
  color: #333;
  display: block;
}

.dropdown-item:hover {
  background-color: #f0f0f0; 
}

.review-background {
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: center; 
    background-color: #FAFAFA;
    width: 100%;
}

.review-section {
    justify-content: center; 
    align-items: center;
    padding: 5px;
    max-width: 1200px;
    align-self: center;
     margin-top: 30px;
          margin-bottom: 50px;
}

.review-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 90%;
    margin-left: 20px;
    height: 250px;
    text-align: flex-start;
}

.quote-icon {
    font-size: 24px;
    color: grey;
    margin-bottom: 10px;
}

.review-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
    height: 110px
}

.reviewer-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}

.reviewer-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.reviewer-name {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.reviewer-position {
    font-size: 12px;
    color: #777;
    margin: 0;
}

.reviewer-name {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.reviewer-position {
    font-size: 12px;
    color: #777;
    margin: 0;
}

.early-access-section {
  padding: 50px 70px;
  background-color: #FAFAFA;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: -20px
}

.early-access-2 {
  align-items: center;
  background-color: white; 
  justify-content: center;
    padding: 100px;
}

.early-section-content {
  text-align: flex-start;
  align-items: center;
      display: flex;
     flex-direction: row
}

.early-section-column {
  text-align: flex-start;
      display: flex;
     flex-direction: column
}

.early-section-content h2 {
  font-size: 2em;
  color: #101828;
  font-weight: 600;
}

.early-section-content p {
  font-size: 1.1em;
  color: #6e6e6e;
  margin-top: 10px;
}

.get-access-button {
  margin-top: 20px; 
}

.btn-get-access {
  background-color: #003366;
  color: white;
  padding: 15px 20px;
    border-radius: 10px;
  font-size: 1.1em;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-get-access:hover {
  background-color: #0056b3;
}

.image-container {
  margin-left: 200px;
  margin-bottom: -60px
}

.access-image {
  width: 100%;
  max-width: 300px; 
  height: auto;
  border-radius: 10px;
}

.connectWithUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px
}

.socialContent {
  text-align: flex-start;
  margin-top: 200px;
  margin-right: 40px
}

 .socialContent h3 {
  font-size: 2.5em;
  color: #101828;
  font-weight: 600;
}

.socialContent p {
  font-size: 1.5em;
  color: #6e6e6e;
  margin-top: 10px;
  width: 500px;
}

.socialLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
    padding: 50px; 
  background-color: #FAFAFA; 
}

.socialRow {
  display: flex;
  gap: 20px;
}

.row-social {
  display: flex;
}

.socialCard {
  text-align: flex-start;
  padding: 10px;
}

.socialIcon {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.platform {
  font-size: 20px;
  font-weight: bold;
}

.description {
  font-size: 14px;
  color: #666; 
  width: 200px;
  margin-top: 5px;
   margin-bottom: 20px;
}

.handle {
  color: blue; 
  text-decoration-line: underline;
  margin-top: 5px;
}

.contactButton {
  margin-top: 20px;
  display: flex;
  background-color: #003366;
  color: white;
  padding: 15px 20px;
  font-size: 1.1em;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.contactButton:hover {
  background-color: #0056b3;
}

.buttonIcon {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-top: 5px
}

.garagetop {
  text-align: flex-start;
  margin-top: 50px;
  margin-bottom: -30px;
  margin-right: 70px
}

.card-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: -20px
}

.card-row {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.car-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 12px;
  padding: 20px;
  text-align: flex-start;
}

.car-card-image {
  width: auto;
  height: auto;
  margin-left: -22px
}

.car-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: -30px
}

.car-card-description {
  font-size: 16px;
  color: #101828;
}

.career-button {
  background-color: #E6EBF0;
  color: #003366;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 250px;
  width: 120px;
  margin-top: 120px;
  margin-bottom: -70px
}

.career-card-button {
  background-color: #E6EBF0;
  color: #003366;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 120px;
}

.career-cards-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  max-width: 1200px;
  background-color: #FAFAFA;
  align-self: center;
  padding-top: 50px;
  padding-bottom: 100px
}

.career-card-content-text h2 {
  font-size: 2em;
   margin-top: 10px;
      margin-bottom: 50px;
  color: #101828;
  font-weight: 600;
  text-align: flex-start;
}

.team-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px;
  padding-top: 0;
  text-align: flex-start;
  align-self: center
}

.team-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 5px;
}

.team-image {
  width: auto;
  height: 250px;
  margin-bottom: 30px;
}

.team-name {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 5px;
  color: #101828;
  text-align: flex-start;
  transition: color 0.3s ease; 
}

.team-name:hover {
  color: #0056b3
}

.team-role {
  font-size: 14px;
  color: #003366;
  font-weight: 600;
  margin-bottom: 10px;
  transition: color 0.3s ease; 
}

.team-role:hover {
  color: #0056b3
}


.social-icons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.social-icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
  color: grey;
  transition: color 0.3s ease;
}

.openpostButton {
  background-color: #003366;
  color: white;
  height: 45px;
  padding: 10px;
  font-size: 1.1em;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: -20px;
  margin-left: 610px
}

.openpostButton:hover {
  background-color: #0056b3;
}

.career-button-row {
  display: flex;
  flex-direction: row
}

.popup-container:hover .popup,
.popup-container:hover .popup2 {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.nav-links .grey {
  color: #667085;
  position: relative;
  transition: all 0.3s ease;
}

.nav-links .grey::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #0056b3;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.nav-links .grey:hover {
  color: #0056b3;
  font-weight: 600;
}

.nav-links .grey:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.auth-buttons button {
  transition: all 0.3s ease;
}

.downloadnow-button {
  transition: all 0.3s ease;
  transform: scale(1);
}

.downloadnow-button:hover {
  transform: scale(1.05);
}

.downloadnow2-button {
  transition: all 0.3s ease;
  transform: scale(1);
}

.downloadnow2-button:hover {
  transform: scale(1.05);
}

@media (max-width: 599px) {
.section-grey-background {
  background: linear-gradient(180deg, white 0%, #D9D9E4 100%);
  text-align: flex-start;
  margin-top: 80px;
  margin-bottom: -10px;
  padding-bottom: 0px;
}
.section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px; 
  margin: 0;
  padding: 0px 20px;
  max-width: 100%
}
.text-and-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  text-align: left;
    max-width: 100%;
}
.text-and-icons h2 {
  font-size: 2.1em;
  color: #101828;
  font-weight: bold;
}
.cards-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  padding: 10px 10px;
  background-color: #FAFAFA;
}
.cards-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  justify-content: flex-start; 
  max-width: 100%;
}
.cards-white {
  width: 100%; 
  margin:20px;
  background-color: #FAFAFA;
  border-width: 0.5px;
  padding: 15px;
  border-radius: 8px;
  overflow: hidden
}
.services-button {
  align-self: flex-start;
  margin-top: 50px;
    width: 100px;
      padding: 10px 10px;
}
.card-content-text {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: flex-start;
  align-self: flex-start;
  margin-top: 20px;
  padding: 0px;
  margin-left: 10px
}
.card-content-text2 {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: flex-start;
  align-self: flex-start;
  margin-top: 20px;
  padding: 0px;
  margin-left: 10px
}
.card-content-text2 h2 {
   width: 100%;
   align-items: flex-start; 
  text-align: left;
  margin-left: 10px;
 margin-right: 10px
}
.card-content-text h2 {
  font-size: 1.5em;
   margin-top: -10px;
  color: #101828;
  font-weight: 600;
  text-align: left;
    align-self: flex-start;
}
.card-content-text p {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #667085;
  text-align: left;
}
.mid-img {
padding: 10px;
margin-bottom: 50px;
}
.review-section {
    justify-content: center; 
    align-items: center;
    padding: 5px;
    max-width: 100%;
    align-self: center;
     margin-top: 30px;
          margin-bottom: 50px;
}
.review-section {
  padding: 10px;
  background-color: #FAFAFA;
     justify-content: center; 
     align-content: center
}
.early-access-section {
  padding: 20px;
  background-color: #FAFAFA;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-top: 60px;
  margin-bottom: -20px
}
.early-access-2 {
  align-items: center;
  background-color: white; 
  justify-content: center;
    padding: 0px;
}
.early-section-content {
  text-align: center;
  align-items: center;
      display: flex;
     flex-direction: column;
     margin-top: 30px;
}
.btn-get-access {
  background-color: #003366;
  color: white;
  padding: 15px 20px;
    border-radius: 0px;
  font-size: 1.1em;
  border: none;
  cursor: pointer;
  width: 300px;
  transition: background-color 0.3s ease;
}
.image-container {
  margin-left: 0px;
    margin-bottom: -30px
}
.access-image {
  width: 30%;
  max-width: 100%; 
  height: 30%;
  margin-left: 20px;
  margin-right: 20px
}
.socialLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
    padding: 10px; 
  background-color: #FAFAFA; 
  margin-top: 30px
}
.socialRow {
  display: flex;
       flex-direction: column;
  gap: 20px;
}
 .socialContent h3 {
  font-size: 2em;
 margin-top: -15px
}
.socialContent p {
  font-size: 1.2em;
  color: #6e6e6e;
  margin-top: 10px;
  width: auto;
}
.socialContent {
  text-align: flex-start;
  margin-top: 40px;
  margin-right: 10px;
  margin-left: 10px
}
.row-social {
  display: flex;
       flex-direction: column;
}
.connect-line {
    border-right-width: 2px;
    border-right-color: none;
    height: 0px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0px
}
.connectWithUs {
  margin-bottom: 50px
}
.hoverp {
  font-size: 1.4em;
}
.hoverp2 {
  font-size: 16px;
}
.card-text {
  font-size: 19px;
    padding: 30px 10px
}
.icon {
  width: 18px;
  height: 18px;
  right: 8px;
}
.card-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 10px
}
.text-and-icons2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  text-align: left;
  max-width: 100%;
  margin-top: -50px;
  padding: 10px;
  margin-right: 0px;
  margin-left: -20px
}

.text-and-icons2 h2 {
  font-size: 2.2em;
  color: #101828;
  font-weight: bold;
  margin-top: 20px;
}
.faq-container {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: white;
  margin-top: 120px;
  margin-bottom: 10px;
}
.faq-container h2 {
font-size: 2.2em;
font-weight: bold;
text-align: left;
color: #101828;
}
.faq-contr p {
font-size: 20px;
text-align: left;
margin-bottom: 40px;
margin-top: 20px;
}
.plus-arrow {
width: 25px;
height: 25px;
margin-left: 5px
}
.faq-section {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  cursor: pointer; 
}
.faq-section:hover {
  border-width: 0;
  border-color: #000;
  border-radius: 0px;
  padding: 20px 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0); 
  transition: all 0.3s ease; 
  width: 100%;
  margin-left: 0px
}
.contact-content {
align-items: flex-start;
justify-content: flex-start;
align-self: flex-start;
padding: 10px
}
.contact-content h2 {
  font-size: 2.2em;
  width: 600px;
   margin-top: 100px;
  color: #101828;
  font-weight: 600;
  text-align: left;
}
.contact-content p {
  font-size: 20px;
  width: 330px;
  margin-top: 10px;
  margin-bottom: -10px;
  text-align: left;
}  
.contact-container {
  display: flex;
flex-direction: column;
margin-right: 0px;
align-self: flex-start;
max-width: 100%;
}
.get-in-touch-form {
  padding: 10px;
  background-color: #fff; 
  border-radius: 20px;
  max-width: 100%;
  align-self: flex-start;
}
.submit-button {
  width: 100%
}
.phone-number-input {
  height: 45px;
  border: 1px solid #D9D9D9;
  padding: 15px; 
  font-size: 16px;
  width: 73%;
  margin-left: 27%;
  position: relative; 
  top: -60px;
  border-radius: 0px,
}
.input-move {
margin-bottom: -40px;
margin-top: 10px
}
.contact-content {
align-items: flex-start;
justify-content: flex-start;
align-self: flex-start
}
.career-button {
  margin-left: 0px;
  margin-top: 120px;
  margin-bottom: -80px
}
.contact-content h2 {
  font-size: 2.2em;
  width: 100%;
   margin-top: 100px;
  color: #101828;
  font-weight: 600;
  text-align: left;
}
.team-section {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px;
  padding-top: 0;
  text-align: flex-start;
  align-self: center
}
.contact-content p {
  font-size: 20px;
  width: 100%;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #667085;
  text-align: left;
}
.team-image {
  width: auto;
  height: auto;
  margin-bottom: 5px;
}
.career-cards-section {
  max-width: 100%;
  align-self: center;
  padding-top: 80px;
  padding-bottom: 100px;
  margin-left: 10px;
  margin-right: 10px
}
.career-card-button {
margin-left: 10px
}
.career-card-content-text h2 {
width: 100%;
font-size: 1.8em;
margin-left: 10px
}
.career-card-content-text p {
  font-size: 20px;
   margin-top: -35px;
      margin-left: 10px;
  color: #667085;
  text-align: flex-start;
}
.career-button-row {
  display: flex;
  flex-direction: column
}
.openpostButton {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px
}
}

@media (max-width: 768px) {
  .popup, .popup2 {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
    padding: 15px;
  }

  .popup-column {
    width: 100%;
  }

  .pop-app-store, .pop-app-store2 {
    margin: 15px 0 0 0;
    width: 100%;
    text-align: center;
  }

  .pop-app-store h2, .pop-app-store2 h2,
  .pop-app-store p, .pop-app-store2 p {
    width: 100%;
    text-align: center;
  }

  .popup-app-store-icons, .popup-app-store-icons2 {
    margin: 15px auto 0;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
  20% {
    transform: translateY(-10px);
  }
}